// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-drazby-js": () => import("./../../../src/pages/drazby.js" /* webpackChunkName: "component---src-pages-drazby-js" */),
  "component---src-pages-formular-odeslan-js": () => import("./../../../src/pages/formular-odeslan.js" /* webpackChunkName: "component---src-pages-formular-odeslan-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakty-js": () => import("./../../../src/pages/kontakty.js" /* webpackChunkName: "component---src-pages-kontakty-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-media-zpravy-js": () => import("./../../../src/pages/media-zpravy.js" /* webpackChunkName: "component---src-pages-media-zpravy-js" */),
  "component---src-pages-publikace-detail-js": () => import("./../../../src/pages/publikace-detail.js" /* webpackChunkName: "component---src-pages-publikace-detail-js" */),
  "component---src-pages-publikace-js": () => import("./../../../src/pages/publikace.js" /* webpackChunkName: "component---src-pages-publikace-js" */),
  "component---src-pages-uredni-deska-js": () => import("./../../../src/pages/uredni-deska.js" /* webpackChunkName: "component---src-pages-uredni-deska-js" */),
  "component---src-pages-zadost-o-splatkovy-kalendar-js": () => import("./../../../src/pages/zadost-o-splatkovy-kalendar.js" /* webpackChunkName: "component---src-pages-zadost-o-splatkovy-kalendar-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

